import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Loading from 'quasar/src/plugins/Loading.js';import Cookies from 'quasar/src/plugins/Cookies.js';;
import { getConfigEnv } from "@/plugins/env";
import router from "@/router";

const env = await getConfigEnv();
const baseUrl = `${env.LINEBOT_BACKEND_URL}/api/linebot`;

const handleRequest = (hideLoading: boolean) => {
  return (config: AxiosRequestConfig) => {
    if (!hideLoading) {
      Loading.show();
    }
    const idToken = Cookies.get(
      `id_token/${router.currentRoute.value.params.accountId}`
    );
    if (config.headers) {
      config.headers["Line-Authorization"] = `Bearer ${idToken}`;
    }
    return config;
  };
};

/* api */
const api = axios.create();
api.defaults.baseURL = baseUrl;
api.defaults.timeout = 30_000;
api.interceptors.request.use(handleRequest(false));
api.interceptors.response.use((response: AxiosResponse) => {
  Loading.hide();
  return response;
});

/* liffApi */
const liffURL = `${baseUrl}/Liff`;
const liffApi = axios.create();
liffApi.defaults.baseURL = liffURL;
liffApi.defaults.timeout = 30_000;
liffApi.interceptors.request.use(handleRequest(false));
liffApi.interceptors.response.use((response: AxiosResponse) => {
  Loading.hide();
  return response;
});

/* nonLoadingLiffApi (Without quasar loading animation) */
const nonLoadingLiffApi = axios.create();
nonLoadingLiffApi.defaults.baseURL = liffURL;
nonLoadingLiffApi.defaults.timeout = 30_000;
nonLoadingLiffApi.interceptors.request.use(handleRequest(true));
nonLoadingLiffApi.interceptors.response.use((response: AxiosResponse) => {
  Loading.hide();
  return response;
});

export default api;
export { liffURL, liffApi, nonLoadingLiffApi };
