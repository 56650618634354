interface BindState {
  memberId: string;
}

const state: BindState = {
  memberId: "",
};

export default {
  state,
  getters: {},
  actions: {},
  mutations: {
    setMemberId(state: BindState, memberId: string) {
      state.memberId = memberId;
    },
  },
  namespaced: true,
};
